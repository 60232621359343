<!--  -->
<template>
  <div>
    <div class="dateils">
      <div class="title">
        <span style="cursor: pointer;" @click="clickBack"><i class="el-icon-arrow-left"></i></span>
        <span>{{type=='反馈'?'反馈位置：':(type=='公告'?'公告：':type)}} <span style="font-weight: 700;color:var(--color-black)">{{showTitle}}</span></span>
        <!-- <span>C语言课程</span> -->
      </div>
      <div class="dateils_content">
        <div class="content-z forum_title" v-if="info.type==2">
          <span>论坛名称：</span>
          <div style="width:60%;white-space:pre-wrap;" v-html="info.forum_title"></div>
        </div>
        <div class="content-z" v-if="type=='反馈'">
            <span>反馈标题：</span>
          <div style="width:60%;white-space:pre-wrap;" v-html="info.title"></div>
        </div>
        <div class="content-z content-detail">
          <span>{{type=='反馈'?'反馈内容：': (type=='公告'?'公告内容：':'消息内容：')}}</span>
          <div v-html="info.content" style="white-space:pre-wrap;"></div>
        </div>
        <div class="content-z" v-if="type=='反馈'">
            <span>反馈回复：</span>
          <div style="white-space:pre-wrap;">{{info.reply_content||'暂未回复'}}</div>
        </div>
        <div class="btn" v-if="info.type==2">
          <p class="button-primary" :style="{ padding:'4px 20px'}" @click="$handleRoute({ id: info.forum_id }, 'forumSee')">查 看</p>
        </div>
        <div class="btn" v-else-if="info.type && info.url">
			    <a class="button-primary" :style="{ padding:'4px 20px'}" target="_blank" :href="info.url" v-if="info.url.indexOf('://') > -1">{{buttonName}}</a>
          <a class="button-primary" :style="{ padding:'4px 20px'}" @click="$router.push(info.url);" :data-route="info.url" v-else>{{buttonName}}</a>
        </div>
        <!-- <div class="btn" v-if="type=='反馈'">
          <p class="button-primary" :style="{ padding:'4px 20px'}" @click="lookFK">查 看</p>
        </div> -->
      </div>

    </div>
  </div>
</template>

<script>
import { feedbackDetails } from "@/api/me.js"
import { read } from "@/api/me.js"
import $ from 'jquery'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {
      item: {},
      id: "",
      url: "",
      info: {},
      type: '反馈'
    }
  },
  //监听属性 类似于data概念
  computed: {
    buttonName(){
      if(this.info.buttonName)return this.info.buttonName;
      if(this.info.type.length > 4) return this.info.type;
      return this.info.type.indexOf('查看') > -1 ? this.info.type : ('查看'+this.info.type);
    },
    showTitle(){
      return this.$jquery('<div>'+(this.info.title||'')+'</div>').text();
    },
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  mounted () {
    console.log(this.$route.query.type,'parent')
   
    let dataInfo = this.$route.query
    this.type = dataInfo.type
    this.id = dataInfo.id
    this.info = { title: dataInfo.title, content: dataInfo.content }
     if(dataInfo.type=='反馈'){
      this.getDetaails()
    }else if(this.id){
       read({ id: this.id })
           .then((res) => {
             this.info = res.data.info;
             this.$nextTick(()=>{
               $('.content-detail [data-]').each((item , idx)=>{
                 console.log("$nextTick data-" , item , idx);
               });
             });
           })
     }

  },
  //方法集合
  methods: {
    getDetaails () {
      feedbackDetails({ id: this.id }).then(({ data }) => {
        console.log(data, '反馈详情成功')
        this.info = data.info
      }).catch((err) => {
        console.log(err, '反馈详情失败')
      })
    },
    clickBack () {
      if (this.info.type==2) {
        this.$routerGo(-1 , {name:'meMessageInteract'})
      } else {
        this.$routerGo(-1 , {name:'meMessageSystem'})
      }


    }
  },
};
</script>
<style  lang='less' scoped>

.dateils {
  background: #ffffff;
  box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 1px solid #979797;
  .title {
    padding: 2% 0 2% 4%;

    border-bottom: 1px solid #979797;
    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.65);
      line-height: 20px;
      &:nth-child(2) {
        margin-left: 3.3%;
      }
    }
  }
  .dateils_content {
    .btn{
      text-align: right;
      margin-bottom: 1rem;
    }
    padding: 4.3% 8.8% 0;
    // display: flex;
    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.65);
      line-height: 20px;
      white-space: nowrap;
    }
    .content-z {
      min-height: 50px;
      display: flex;
    }
    .content-z,.forum_title  {
     
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 22px;
    
      padding-bottom: 2%;
    }
  }
}
</style>